<template>
    <div id="NewsGallery" class="w80">
        <h2 class="f29 f22-mb w90-mb">過去の記事</h2>
        <ul class="gallery-group w50-mb">
            <li
                v-for="(item, index) in newsReverse.slice(
                    (currPage - 1) * pageSize,
                    currPage * pageSize
                )"
                :key="'gallery' + index"
            >
                <router-link
                    :to="{
                        name: 'News',
                        params: {
                            newsId: item.id,
                        },
                    }"
                >
                    <img v-lazy="item.imageURL" alt="activity image" />
                    <h4 class="f14 f12-mb">{{ item.date }}</h4>
                    <h3 class="f18 f16-mb">{{ item.title }}</h3>
                </router-link>
            </li>
        </ul>
        <div class="pagination-group serif">
            <p class="f12 f12-mb">
                {{ currPage + ' / ' + totalPages }}
            </p>
            <div class="pagination f16 f12-mb">
                <span
                    v-show="currPage !== 1"
                    class="to-first"
                    @click="currPage = 1"
                >
                    <i class="icon-left-left"></i>
                    最前
                </span>
                <span
                    v-show="currPage !== 1"
                    class="to-prev"
                    @click="currPage -= 1"
                >
                    <i class="icon-left-left"></i>
                </span>
                <ElPagination
                    layout="pager"
                    :total="news.length"
                    v-model:page-size="pageSize"
                    v-model:current-page="currPage"
                ></ElPagination>
                <span
                    v-show="currPage !== totalPages"
                    class="to-next"
                    @click="currPage += 1"
                >
                    <i class="icon-right-right"></i>
                </span>
                <span
                    v-show="currPage !== totalPages"
                    class="to-last"
                    @click="currPage = totalPages"
                >
                    最後
                    <i class="icon-right-right"></i>
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import getNewsData from '@/data/getNewsData.js'
import { computed, ref } from 'vue-demi'
import { ElPagination } from 'element-plus'
export default {
    name: 'NewsGallery',
    components: { ElPagination },
    setup() {
        const pageSize = computed(() => {
            if (window.innerWidth >= 768) return 6
            else return 3
        })
        const { news } = getNewsData()
        const newsReverse = news.slice().reverse()
        const currPage = ref(1)
        const totalPages = computed(() =>
            Math.ceil(news.length / pageSize.value)
        )

        return {
            news,
            newsReverse,
            pageSize,
            currPage,
            totalPages,
        }
    },
}
</script>
<style lang="scss" scoped>
#NewsGallery {
    background-color: $white;
    box-sizing: border-box;
}

.gallery-group {
    li {
        a {
            display: block;
            color: $gray0;
            transition: 0.3s;
            img {
                display: block;
                max-width: 240px;
                width: 100%;
                height: 180px;
                object-fit: cover;
                background-color: $minorGold;
            }
            h4 {
                margin-top: 5px;
            }
            h3 {
                line-height: 1.5em;
                max-height: 3em;
                overflow: hidden;
            }
            &:hover {
                color: $gray6;
            }
        }
    }
}
.pagination-group {
    text-align: center;
    font-weight: 700;
    margin: 50px 0;
    p {
        margin-bottom: 20px;
    }
    .pagination {
        display: flex;
        justify-content: center;
        line-height: 2em;
        cursor: pointer;
        /deep/ .el-pagination {
            .el-pager {
                display: flex;
                li {
                    width: 2em;
                    height: 2em;
                    border-radius: 50%;
                    margin: 0 0.1em;
                }
                li.is-active {
                    color: $white;
                    background-color: $majorPurple;
                }
                li.more {
                    svg {
                        transform: scale(0.5);
                    }
                }
            }
        }
        span {
            margin: 0 0.3em;
            i {
                display: inline-block;
                transform: scale(0.5);
            }
        }
    }
}
@media screen and (min-width: 768px) {
    #NewsGallery {
        padding: 50px 80px;
    }
    h2 {
        @include h1Style();
        margin: 30px 0;
    }
    .gallery-group {
        margin: 50px 0;
        display: grid;
        grid-template: auto / repeat(3, 1fr);
        grid-gap: 40px;
    }
}
@media screen and(max-width:767px) {
    h2 {
        border-top: 1px solid $grayE;
        text-align: center;
        padding: 50px 0;
    }
    .gallery-group {
        li {
            margin: 30px 0;
        }
    }
}
</style>
